import React from "react"
import { Content, Layout, SEO } from "../components"
import styled from "styled-components"

const Code = styled.code`
  white-space: nowrap;
  display: inline-block;
  overflow: scroll;
  max-width: 100%;

  @media (min-width: ${(props) => props.theme.sizes.min.m}) {
    overflow: auto;
  }
`

const About = () => {
  return (
    <Layout>
      <SEO />
      <Content>
        <h1>Kepi</h1>

        <p>
          Free software enthusiast, developer and Linux sysadmin turned to <s>Dark</s>{" "}
          <a href="https://github.com/emacs-evil/evil">Evil</a> Side of Emacs.
        </p>

        <h3>Public Keys</h3>
        <dl>
          <dt>GPG ID</dt>
          <dd>
            <Code>DFB81CA2333149FB</Code>
          </dd>
          <dt>GPG Fingerprint</dt>
          <dd>
            <Code>3A54 F62B D0BB A484 79A0 0C44 DFB8 1CA2 3331 49FB</Code>
          </dd>
          <dt>SSH Key</dt>
          <dd>
            <Code>
              ssh-ed25519 AAAAC3NzaC1lZDI1NTE5AAAAIK5mya1x1gZmDSqWbvn4s7YbSt67hU7mz81ZYSSwtm1W
              kepi@starbug
            </Code>
          </dd>
        </dl>
      </Content>
    </Layout>
  )
}

export default About
